<template>
  <v-dialog v-model="dialog" persistent max-width="600">
    <v-card class="pt-5">
      <v-card-title
        class="mb-16 text-h5 d-flex justify-center font-weight-black"
      >
        PAGAR INTERNET MÓVIL
      </v-card-title>
      <v-card-text class="d-flex justify-center">
        <icon-alert class="icon--alert"></icon-alert>
      </v-card-text>
      <v-card-text class="mb-16">
        <p class="black--text text-center pl-16 pr-16">
          ¿Esta seguro que quiere cambiar el estado de este plan de pendiente a
          pagado?
        </p>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions class="d-flex justify-center mt-8 pb-8">
        <v-btn
          class="pr-5 pl-5 ml-5"
          color="error"
          depressed
          @click="$emit('cancel')"
        >
          CANCELAR
        </v-btn>
        <v-btn class="pr-5 pl-5 ml-5" color="primary" depressed @click="$emit('toPay')">
          SI, PAGAR
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "ConfirmPayment",
  components: {
    IconAlert: () => import("../icons/IconAlert"),
  },
  props: {
    dialog: {
      type: Boolean,
      required: true,
    },
  }
};
</script>

<style lang="scss" scoped>
.icon--alert {
  max-width: 8rem;
}
</style>